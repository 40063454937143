export function SmallLogoSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="smalllogo"
      x="0"
      y="0"
      viewBox="0 0 70 70"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {`.st0{fill:#008DA9;}
        .st1{fill:#FFFFFF;}`}
      </style>
      <path
        className="st0"
        d="M48.06 28.91l-4.2 4.2 4.2 4.2 4.2-4.2L48.06 28.91zM46.58 33.1l1.48-1.48 1.48 1.48 -1.48 1.48L46.58 33.1zM21.94 28.91l-4.2 4.2 4.2 4.2 4.2-4.2L21.94 28.91zM20.46 33.1l1.48-1.48 1.48 1.48 -1.48 1.48L20.46 33.1zM48.06 39.23l-4.2 4.2 4.2 4.2 4.2-4.2L48.06 39.23zM46.58 43.43l1.48-1.48 1.48 1.48 -1.48 1.48L46.58 43.43zM21.94 39.23l-4.2 4.2 4.2 4.2 4.2-4.2L21.94 39.23zM20.46 43.43l1.48-1.48 1.48 1.48 -1.48 1.48L20.46 43.43z"
      />
      <path
        className="st1"
        d="M41.53 22.37l-4.2 4.2 4.2 4.2 4.2-4.2L41.53 22.37zM40.05 26.57l1.48-1.48L43 26.57l-1.48 1.48L40.05 26.57zM28.47 22.37l-4.2 4.2 4.2 4.2 4.2-4.2L28.47 22.37zM27 26.57l1.48-1.48 1.48 1.48 -1.48 1.48L27 26.57zM35 28.9l-4.2 4.2 4.2 4.2 4.2-4.2L35 28.9zM33.52 33.1L35 31.62l1.48 1.48L35 34.57 33.52 33.1z"
      />
    </svg>
  );
}
