export function LogoSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="logo"
      x="0"
      y="0"
      viewBox="0 0 350 90"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {`.st0{fill:#008DA9;}
        .st1{fill:#FFFFFF;}`}
      </style>
      <path
        className="st0"
        d="M309.86 34.59l-7.19 7.19 7.19 7.19 7.19-7.19L309.86 34.59zM307.33 41.79l2.53-2.53 2.53 2.53 -2.53 2.53L307.33 41.79zM265.09 34.59l-7.19 7.19 7.19 7.19 7.19-7.19L265.09 34.59zM262.56 41.79l2.53-2.53 2.53 2.53 -2.53 2.53L262.56 41.79zM309.86 52.3l-7.19 7.19 7.19 7.19 7.19-7.19L309.86 52.3zM307.33 59.49l2.53-2.53 2.53 2.53 -2.53 2.53L307.33 59.49zM265.09 52.3l-7.19 7.19 7.19 7.19 7.19-7.19L265.09 52.3zM262.56 59.49l2.53-2.53 2.53 2.53 -2.53 2.53L262.56 59.49z"
      />
      <path
        className="st1"
        d="M298.66 23.39l-7.19 7.19 7.19 7.19 7.19-7.19L298.66 23.39zM296.14 30.59l2.53-2.53 2.53 2.53 -2.53 2.53L296.14 30.59zM276.28 23.39l-7.19 7.19 7.19 7.19 7.19-7.19L276.28 23.39zM273.76 30.59l2.53-2.53 2.53 2.53 -2.53 2.53L273.76 30.59zM287.47 34.58l-7.19 7.19 7.19 7.19 7.19-7.19L287.47 34.58zM284.94 41.78l2.53-2.53 2.53 2.53 -2.53 2.53L284.94 41.78z"
      />
      <path
        className="st1"
        d="M49.67 54.96h-4.9v-8.98l-3.2 4.25h-0.54l-3.17-4.25v8.98h-4.9V35.01h1.73l6.63 8.59 6.63-8.59h1.73V54.96zM64.68 54.96l-0.88-2.47h-5.64l-0.88 2.47h-5.13v-0.31l7.62-19.81h2.44l7.54 19.81v0.31H64.68zM62.42 48.47c-0.94-2.86-1.13-3.88-1.45-5.64 -0.4 1.96-0.6 2.89-1.42 5.64H62.42zM81.44 54.93l-2.55-6.21h-1.87v6.21h-4.9V35.12h7c4.02 0 6.69 2.81 6.69 7 -0.03 2.27-0.62 4.05-2.38 5.36l3.37 7.2v0.26H81.44zM79.09 44.5c1.19 0 1.98-0.99 1.98-2.49 0-1.45-0.79-2.41-1.98-2.41h-2.15v4.9H79.09zM95.46 42.89c0.51 0 1.62 0.06 2.18-0.31 0.71-0.45 0.94-1.64 1.42-4.02 0.48-2.41 2.21-4.36 6.29-3.4v3.49c-0.88-0.09-1.56 0.2-1.81 1.42 -0.54 2.38-1.13 4.05-2.01 5.27 1.53 2.95 3 5.92 4.59 8.87v0.77h-5.56l-3.34-7.65c-0.4 0.09-1.22 0.09-1.76 0.09v7.57h-4.9V35.12h4.9V42.89zM109.85 54.96v-4.19h1.98V39.32h-1.79v-4.2h8.5v4.2h-1.81v11.45h2.01v4.19H109.85zM128.73 42.46H134V47h-5.27v3.23h6.01v4.73h-10.88V35.12h10.66v4.51h-5.78V42.46zM146.41 50.34h6.29v4.62H139.3v-0.74l6.21-14.6h-5.64v-4.51h12.78v0.77L146.41 50.34zM162.35 42.46h5.27V47h-5.27v3.23h6.01v4.73h-10.88V35.12h10.66v4.51h-5.78V42.46zM173.45 54.96V34.95h1.53l8.36 9.64v-9.47h4.9V55.1h-1.42l-8.47-9.81v9.67H173.45zM198.85 47.02v7.94h-4.9V35.12h4.9v7.17h4.48v-7.17h4.9v19.84h-4.9v-7.94H198.85zM220.65 55.24c-5.22 0-8.7-4.08-8.7-10.2 0-6.12 3.49-10.2 8.7-10.2 5.19 0 8.64 4.08 8.64 10.2C229.29 51.16 225.83 55.24 220.65 55.24zM220.65 50.4c2.24 0 3.71-2.18 3.71-5.36s-1.47-5.33-3.71-5.33c-2.3 0-3.83 2.15-3.83 5.33S218.35 50.4 220.65 50.4zM237.75 54.96h-4.9V35.12h10.66v4.51h-5.75v4.39h5.02v4.53h-5.02V54.96z"
      />
    </svg>
  );
}
